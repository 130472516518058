import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { StaticImage } from "gatsby-plugin-image";
import appData from "../../../../app.json";

import * as styles from "./Support.module.scss";

const Support: React.FC = () => {
  return (
    <section className={styles.Support}>
      <h3 className={styles.Support_title}>
        Support and resources you need whenever you do
      </h3>

      <div className={styles.Support_content}>
        <StaticImage
          src="../../../assets/media/images/support.png"
          alt="support and resources"
          className={styles.Support_content_img}
        />

        <div className={styles.Support_content_info}>
          <div className={styles.Support_content_info_text}>
            <p>
              Everyday, we ask new questions on what it means to support your
              business beyond issue resolutions.
            </p>
            <p>
              We are adding resources, product guides, a thriving community and
              everything in-between. Our support team is here to help your
              business succeed. We are an extension of your team, always on
              standby to provide kind and empathetic support whenever you need
              it.
            </p>
            <p>Talk to us anytime.</p>
          </div>
          <Button
            variant={ButtonVariant.Primary}
            className={styles.Support_content_info_btn}
            onClick={() => window.open(`tel:${appData.phone}`, "_target")}
          >
            Contact us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Support;
