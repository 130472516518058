import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./TextBanner.module.scss";

const TextBanner: React.FC = () => {
  return (
    <section className={styles.TextBanner}>
      <div className={styles.TextBanner_container}>
        <div className={styles.TextBanner_img}>
          <StaticImage
            src="../../../assets/media/images/cards/text-banner-bg.png"
            alt="text-banner"
            loading="eager"
          />
        </div>
        <div className={styles.TextBanner_info}>
          <h3 className={styles.TextBanner_info_title}>
            The corporate card made for business
          </h3>
          <p className={styles.TextBanner_info_text}>
            From offsite expenses to field sales and business travel, stay on
            top of your spending. Spend from one place with the visibility and
            control your business deserves.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TextBanner;
