import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./ImageBanner.module.scss";

const ImageBanner: React.FC = () => {
  return (
    <section className={styles.ImageBanner}>
      <span className={styles.ImageBanner_meta}>YOU’RE IN GREAT COMPANY</span>
      <StaticImage
        src="../../../assets/media/images/cards/banner.png"
        alt="Cards image banner"
        className={styles.ImageBanner_img}
      />
    </section>
  );
};

export default ImageBanner;
