import React from "react";
import { CARDS_DATA } from "data/pages";
import { uniqueId } from "lodash";
import cx from "classnames";

import * as styles from "./CorporateCards.module.scss";

const CorporateCards: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <section className={styles.CorporateCards}>
      <div className={styles.CorporateCards_container}>
        <header className={styles.CorporateCards_header}>
          <span className={styles.CorporateCards_header_meta}>
            Corporate cards like you’ve always imagined
          </span>

          <h3 className={styles.CorporateCards_header_title}>
            Issue corporate cards to teams in minutes
          </h3>

          <p className={styles.CorporateCards_header_text}>
            Get your teams started with corporate cards that delivers
            unparalleled visibility and the right set of tools you need to
            operate your business.
          </p>
        </header>

        <ul className={styles.CorporateCards_content}>
          {CARDS_DATA.CORPORATE_CARDS_DATA.map(({ topic, text, img }) => (
            <li
              key={uniqueId("corporate-cards_")}
              className={styles.CorporateCards_content_item}
            >
              <div className={styles.CorporateCards_content_item_info}>
                <h3>{topic}</h3>
                <p>{text}</p>
              </div>

              <div className={styles.CorporateCards_content_item_img}>
                <img src={img} alt={topic} />
              </div>
            </li>
          ))}
        </ul>

        <div className={styles.CorporateCards_mobile}>
          <div className={styles.CorporateCards_mobile_content}>
            <div className={styles.CorporateCards_mobile_content_info}>
              <h3>{CARDS_DATA.CORPORATE_CARDS_DATA[activeIndex].topic}</h3>
              <p>{CARDS_DATA.CORPORATE_CARDS_DATA[activeIndex].text}</p>
            </div>

            <div
              className={cx(styles.CorporateCards_mobile_content_img, {
                [styles.CorporateCards_mobile_content_img_width]:
                  activeIndex === 2,
              })}
            >
              <img
                src={CARDS_DATA.CORPORATE_CARDS_DATA[activeIndex].img}
                alt={CARDS_DATA.CORPORATE_CARDS_DATA[activeIndex].topic}
              />
            </div>
          </div>

          <div className={styles.CorporateCards_mobile_indicators}>
            {CARDS_DATA.CORPORATE_CARDS_DATA.map((_, index) => (
              <div
                className={cx(
                  styles.CorporateCards_mobile_indicators_indicator,
                  {
                    [styles.CorporateCards_mobile_indicators_indicator_active]:
                      activeIndex === index,
                  }
                )}
                key={index}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorporateCards;
