// extracted by mini-css-extract-plugin
export var CorporateCards = "CorporateCards-module--CorporateCards--Y6s9E";
export var CorporateCards_container = "CorporateCards-module--CorporateCards_container--4qUc9";
export var CorporateCards_content = "CorporateCards-module--CorporateCards_content--JygBe";
export var CorporateCards_content_item = "CorporateCards-module--CorporateCards_content_item--MOoA1";
export var CorporateCards_content_item_img = "CorporateCards-module--CorporateCards_content_item_img--+PWqX";
export var CorporateCards_content_item_info = "CorporateCards-module--CorporateCards_content_item_info--MJazW";
export var CorporateCards_header = "CorporateCards-module--CorporateCards_header--KdrPn";
export var CorporateCards_header_meta = "CorporateCards-module--CorporateCards_header_meta--0m7i+";
export var CorporateCards_header_text = "CorporateCards-module--CorporateCards_header_text--DRGsB";
export var CorporateCards_header_title = "CorporateCards-module--CorporateCards_header_title--N+QiR";
export var CorporateCards_mobile = "CorporateCards-module--CorporateCards_mobile--Ey9mL";
export var CorporateCards_mobile_content = "CorporateCards-module--CorporateCards_mobile_content--kC8Ht";
export var CorporateCards_mobile_content_img = "CorporateCards-module--CorporateCards_mobile_content_img--DOKxR";
export var CorporateCards_mobile_content_img_width = "CorporateCards-module--CorporateCards_mobile_content_img_width--gGCqy";
export var CorporateCards_mobile_content_info = "CorporateCards-module--CorporateCards_mobile_content_info--Gc1E8";
export var CorporateCards_mobile_indicators = "CorporateCards-module--CorporateCards_mobile_indicators--OColu";
export var CorporateCards_mobile_indicators_indicator = "CorporateCards-module--CorporateCards_mobile_indicators_indicator--8zdh9";
export var CorporateCards_mobile_indicators_indicator_active = "CorporateCards-module--CorporateCards_mobile_indicators_indicator_active--qN9oN";
export var container = "CorporateCards-module--container--GIu+N";
export var gatsbyImageWrapperConstrained = "CorporateCards-module--gatsby-image-wrapper-constrained--BKDsU";
export var gridSpan1010 = "CorporateCards-module--grid-span-10-10--v2uBC";
export var gridSpan1011 = "CorporateCards-module--grid-span-10-11--sJEzg";
export var gridSpan102 = "CorporateCards-module--grid-span-10-2--1-NSi";
export var gridSpan103 = "CorporateCards-module--grid-span-10-3--IMMyN";
export var gridSpan104 = "CorporateCards-module--grid-span-10-4--IZj5-";
export var gridSpan105 = "CorporateCards-module--grid-span-10-5--aztPn";
export var gridSpan106 = "CorporateCards-module--grid-span-10-6--sOIT3";
export var gridSpan107 = "CorporateCards-module--grid-span-10-7--c+SSX";
export var gridSpan108 = "CorporateCards-module--grid-span-10-8--S17ox";
export var gridSpan109 = "CorporateCards-module--grid-span-10-9--3qt5c";
export var gridSpan110 = "CorporateCards-module--grid-span-1-10---eiuN";
export var gridSpan111 = "CorporateCards-module--grid-span-1-11--bKOKh";
export var gridSpan1110 = "CorporateCards-module--grid-span-11-10--LpXTA";
export var gridSpan1111 = "CorporateCards-module--grid-span-11-11--XxvEM";
export var gridSpan112 = "CorporateCards-module--grid-span-11-2--AacmF";
export var gridSpan113 = "CorporateCards-module--grid-span-11-3--wdE-E";
export var gridSpan114 = "CorporateCards-module--grid-span-11-4--EgOtI";
export var gridSpan115 = "CorporateCards-module--grid-span-11-5--Qxqg7";
export var gridSpan116 = "CorporateCards-module--grid-span-11-6--FXO6g";
export var gridSpan117 = "CorporateCards-module--grid-span-11-7--Xlmzd";
export var gridSpan118 = "CorporateCards-module--grid-span-11-8--M8OB0";
export var gridSpan119 = "CorporateCards-module--grid-span-11-9--2JSwK";
export var gridSpan12 = "CorporateCards-module--grid-span-1-2--mFkUV";
export var gridSpan1210 = "CorporateCards-module--grid-span-12-10--CytpD";
export var gridSpan1211 = "CorporateCards-module--grid-span-12-11--vE7h7";
export var gridSpan122 = "CorporateCards-module--grid-span-12-2--Z-IGp";
export var gridSpan123 = "CorporateCards-module--grid-span-12-3--61D67";
export var gridSpan124 = "CorporateCards-module--grid-span-12-4---GKjL";
export var gridSpan125 = "CorporateCards-module--grid-span-12-5--sd1jg";
export var gridSpan126 = "CorporateCards-module--grid-span-12-6--Kv2Zf";
export var gridSpan127 = "CorporateCards-module--grid-span-12-7--z-P2x";
export var gridSpan128 = "CorporateCards-module--grid-span-12-8--mA97y";
export var gridSpan129 = "CorporateCards-module--grid-span-12-9--Ak3+Q";
export var gridSpan13 = "CorporateCards-module--grid-span-1-3--sYouh";
export var gridSpan14 = "CorporateCards-module--grid-span-1-4--cM5Dq";
export var gridSpan15 = "CorporateCards-module--grid-span-1-5--2xYVV";
export var gridSpan16 = "CorporateCards-module--grid-span-1-6--+qoFB";
export var gridSpan17 = "CorporateCards-module--grid-span-1-7--rwcnR";
export var gridSpan18 = "CorporateCards-module--grid-span-1-8--fxUgA";
export var gridSpan19 = "CorporateCards-module--grid-span-1-9--YD8yr";
export var gridSpan210 = "CorporateCards-module--grid-span-2-10--oGalf";
export var gridSpan211 = "CorporateCards-module--grid-span-2-11--YOE5Y";
export var gridSpan22 = "CorporateCards-module--grid-span-2-2--CLKit";
export var gridSpan23 = "CorporateCards-module--grid-span-2-3--5X8h9";
export var gridSpan24 = "CorporateCards-module--grid-span-2-4--qY77v";
export var gridSpan25 = "CorporateCards-module--grid-span-2-5--za4nx";
export var gridSpan26 = "CorporateCards-module--grid-span-2-6--XU1Tx";
export var gridSpan27 = "CorporateCards-module--grid-span-2-7--R-jMl";
export var gridSpan28 = "CorporateCards-module--grid-span-2-8--5IcAm";
export var gridSpan29 = "CorporateCards-module--grid-span-2-9--P7hgF";
export var gridSpan310 = "CorporateCards-module--grid-span-3-10--uiJxL";
export var gridSpan311 = "CorporateCards-module--grid-span-3-11--PuH9l";
export var gridSpan32 = "CorporateCards-module--grid-span-3-2--UA-GR";
export var gridSpan33 = "CorporateCards-module--grid-span-3-3--1ORKE";
export var gridSpan34 = "CorporateCards-module--grid-span-3-4--NboZL";
export var gridSpan35 = "CorporateCards-module--grid-span-3-5--O-hvx";
export var gridSpan36 = "CorporateCards-module--grid-span-3-6--3ITPV";
export var gridSpan37 = "CorporateCards-module--grid-span-3-7--jcCAg";
export var gridSpan38 = "CorporateCards-module--grid-span-3-8--6H7i0";
export var gridSpan39 = "CorporateCards-module--grid-span-3-9--F+Tz7";
export var gridSpan410 = "CorporateCards-module--grid-span-4-10--FOjEo";
export var gridSpan411 = "CorporateCards-module--grid-span-4-11--Wg5cB";
export var gridSpan42 = "CorporateCards-module--grid-span-4-2--zfbuU";
export var gridSpan43 = "CorporateCards-module--grid-span-4-3--Hsi69";
export var gridSpan44 = "CorporateCards-module--grid-span-4-4--3nTxc";
export var gridSpan45 = "CorporateCards-module--grid-span-4-5--tj0gS";
export var gridSpan46 = "CorporateCards-module--grid-span-4-6--oxiG9";
export var gridSpan47 = "CorporateCards-module--grid-span-4-7--YAtWP";
export var gridSpan48 = "CorporateCards-module--grid-span-4-8--F1Vid";
export var gridSpan49 = "CorporateCards-module--grid-span-4-9--ta4B5";
export var gridSpan510 = "CorporateCards-module--grid-span-5-10--p5HDE";
export var gridSpan511 = "CorporateCards-module--grid-span-5-11--HUdur";
export var gridSpan52 = "CorporateCards-module--grid-span-5-2--D+6--";
export var gridSpan53 = "CorporateCards-module--grid-span-5-3--im1gs";
export var gridSpan54 = "CorporateCards-module--grid-span-5-4--xl0Bh";
export var gridSpan55 = "CorporateCards-module--grid-span-5-5--52Mg8";
export var gridSpan56 = "CorporateCards-module--grid-span-5-6--Nc8TY";
export var gridSpan57 = "CorporateCards-module--grid-span-5-7--htyxP";
export var gridSpan58 = "CorporateCards-module--grid-span-5-8--rMG3L";
export var gridSpan59 = "CorporateCards-module--grid-span-5-9--4KAtx";
export var gridSpan610 = "CorporateCards-module--grid-span-6-10--DOj0d";
export var gridSpan611 = "CorporateCards-module--grid-span-6-11--+cEXR";
export var gridSpan62 = "CorporateCards-module--grid-span-6-2--SIoi5";
export var gridSpan63 = "CorporateCards-module--grid-span-6-3--zgoPY";
export var gridSpan64 = "CorporateCards-module--grid-span-6-4--ZTEX6";
export var gridSpan65 = "CorporateCards-module--grid-span-6-5--ldQi2";
export var gridSpan66 = "CorporateCards-module--grid-span-6-6--k3mY3";
export var gridSpan67 = "CorporateCards-module--grid-span-6-7--Rtw+x";
export var gridSpan68 = "CorporateCards-module--grid-span-6-8--+K9gl";
export var gridSpan69 = "CorporateCards-module--grid-span-6-9--cuxm6";
export var gridSpan710 = "CorporateCards-module--grid-span-7-10--XIOOG";
export var gridSpan711 = "CorporateCards-module--grid-span-7-11--NTsM3";
export var gridSpan72 = "CorporateCards-module--grid-span-7-2--cOtPW";
export var gridSpan73 = "CorporateCards-module--grid-span-7-3--3WRKG";
export var gridSpan74 = "CorporateCards-module--grid-span-7-4--0ArtR";
export var gridSpan75 = "CorporateCards-module--grid-span-7-5--CxBSv";
export var gridSpan76 = "CorporateCards-module--grid-span-7-6--evdYt";
export var gridSpan77 = "CorporateCards-module--grid-span-7-7--qn-N4";
export var gridSpan78 = "CorporateCards-module--grid-span-7-8--7uetR";
export var gridSpan79 = "CorporateCards-module--grid-span-7-9--Med6S";
export var gridSpan810 = "CorporateCards-module--grid-span-8-10--l9YYm";
export var gridSpan811 = "CorporateCards-module--grid-span-8-11--vn81F";
export var gridSpan82 = "CorporateCards-module--grid-span-8-2--luYXv";
export var gridSpan83 = "CorporateCards-module--grid-span-8-3--7-r0E";
export var gridSpan84 = "CorporateCards-module--grid-span-8-4--Vc1qd";
export var gridSpan85 = "CorporateCards-module--grid-span-8-5--ExygU";
export var gridSpan86 = "CorporateCards-module--grid-span-8-6--0tBF1";
export var gridSpan87 = "CorporateCards-module--grid-span-8-7--oTa2D";
export var gridSpan88 = "CorporateCards-module--grid-span-8-8--deAQN";
export var gridSpan89 = "CorporateCards-module--grid-span-8-9--SfHOh";
export var gridSpan910 = "CorporateCards-module--grid-span-9-10--48L9a";
export var gridSpan911 = "CorporateCards-module--grid-span-9-11--UYdCx";
export var gridSpan92 = "CorporateCards-module--grid-span-9-2--Cgbhw";
export var gridSpan93 = "CorporateCards-module--grid-span-9-3--ifRrm";
export var gridSpan94 = "CorporateCards-module--grid-span-9-4--attR7";
export var gridSpan95 = "CorporateCards-module--grid-span-9-5--bGYzu";
export var gridSpan96 = "CorporateCards-module--grid-span-9-6--Bs9t-";
export var gridSpan97 = "CorporateCards-module--grid-span-9-7--APrF3";
export var gridSpan98 = "CorporateCards-module--grid-span-9-8--XBnG8";
export var gridSpan99 = "CorporateCards-module--grid-span-9-9--wx8E4";
export var textBreak = "CorporateCards-module--text-break--XM4o2";