// extracted by mini-css-extract-plugin
export var Testimonials = "Testimonials-module--Testimonials--GXt+8";
export var Testimonials_content = "Testimonials-module--Testimonials_content--QYdKA";
export var Testimonials_content_details = "Testimonials-module--Testimonials_content_details--oXm+A";
export var Testimonials_content_details_company = "Testimonials-module--Testimonials_content_details_company--Qpk3y";
export var Testimonials_content_details_info = "Testimonials-module--Testimonials_content_details_info--QCGND";
export var Testimonials_content_details_info_controls = "Testimonials-module--Testimonials_content_details_info_controls--qLxSJ";
export var Testimonials_content_details_info_controls_btn = "Testimonials-module--Testimonials_content_details_info_controls_btn--rBsuX";
export var Testimonials_content_details_info_quote = "Testimonials-module--Testimonials_content_details_info_quote--F5jYN";
export var Testimonials_content_img = "Testimonials-module--Testimonials_content_img--xnNMD";
export var Testimonials_content_mobile = "Testimonials-module--Testimonials_content_mobile--oMLp6";
export var Testimonials_content_mobile_btn = "Testimonials-module--Testimonials_content_mobile_btn--lWeE4";
export var Testimonials_title = "Testimonials-module--Testimonials_title--bGKMO";
export var container = "Testimonials-module--container--8yama";
export var gatsbyImageWrapperConstrained = "Testimonials-module--gatsby-image-wrapper-constrained--LibqD";
export var gridSpan1010 = "Testimonials-module--grid-span-10-10--ruios";
export var gridSpan1011 = "Testimonials-module--grid-span-10-11--B3ADi";
export var gridSpan102 = "Testimonials-module--grid-span-10-2--bgXRM";
export var gridSpan103 = "Testimonials-module--grid-span-10-3--jxRA-";
export var gridSpan104 = "Testimonials-module--grid-span-10-4--9ibjt";
export var gridSpan105 = "Testimonials-module--grid-span-10-5--IZepc";
export var gridSpan106 = "Testimonials-module--grid-span-10-6--OubiI";
export var gridSpan107 = "Testimonials-module--grid-span-10-7--PK5iy";
export var gridSpan108 = "Testimonials-module--grid-span-10-8--ZT73e";
export var gridSpan109 = "Testimonials-module--grid-span-10-9--bbfj0";
export var gridSpan110 = "Testimonials-module--grid-span-1-10--p6hFI";
export var gridSpan111 = "Testimonials-module--grid-span-1-11--mCN3I";
export var gridSpan1110 = "Testimonials-module--grid-span-11-10--UkO1K";
export var gridSpan1111 = "Testimonials-module--grid-span-11-11--X1snT";
export var gridSpan112 = "Testimonials-module--grid-span-11-2--E1dJY";
export var gridSpan113 = "Testimonials-module--grid-span-11-3--vsoSh";
export var gridSpan114 = "Testimonials-module--grid-span-11-4--LtJGv";
export var gridSpan115 = "Testimonials-module--grid-span-11-5--lfs6t";
export var gridSpan116 = "Testimonials-module--grid-span-11-6--9P+ZR";
export var gridSpan117 = "Testimonials-module--grid-span-11-7--ECgNv";
export var gridSpan118 = "Testimonials-module--grid-span-11-8--UObFa";
export var gridSpan119 = "Testimonials-module--grid-span-11-9--3mOnL";
export var gridSpan12 = "Testimonials-module--grid-span-1-2--KHH54";
export var gridSpan1210 = "Testimonials-module--grid-span-12-10--9g7nE";
export var gridSpan1211 = "Testimonials-module--grid-span-12-11--BLmUX";
export var gridSpan122 = "Testimonials-module--grid-span-12-2--Qm2Fg";
export var gridSpan123 = "Testimonials-module--grid-span-12-3--c79qs";
export var gridSpan124 = "Testimonials-module--grid-span-12-4--Fc1ua";
export var gridSpan125 = "Testimonials-module--grid-span-12-5--PS8yJ";
export var gridSpan126 = "Testimonials-module--grid-span-12-6--BC98j";
export var gridSpan127 = "Testimonials-module--grid-span-12-7--ubGzB";
export var gridSpan128 = "Testimonials-module--grid-span-12-8--FJyWf";
export var gridSpan129 = "Testimonials-module--grid-span-12-9--zR-wi";
export var gridSpan13 = "Testimonials-module--grid-span-1-3--qWfUt";
export var gridSpan14 = "Testimonials-module--grid-span-1-4--U3XmA";
export var gridSpan15 = "Testimonials-module--grid-span-1-5--ZL0NX";
export var gridSpan16 = "Testimonials-module--grid-span-1-6--Txj65";
export var gridSpan17 = "Testimonials-module--grid-span-1-7--obQBb";
export var gridSpan18 = "Testimonials-module--grid-span-1-8--V7Tow";
export var gridSpan19 = "Testimonials-module--grid-span-1-9--qWzCS";
export var gridSpan210 = "Testimonials-module--grid-span-2-10--16ThR";
export var gridSpan211 = "Testimonials-module--grid-span-2-11--uiIbL";
export var gridSpan22 = "Testimonials-module--grid-span-2-2--cnCEu";
export var gridSpan23 = "Testimonials-module--grid-span-2-3--MABkl";
export var gridSpan24 = "Testimonials-module--grid-span-2-4--PxKXV";
export var gridSpan25 = "Testimonials-module--grid-span-2-5--FcVx0";
export var gridSpan26 = "Testimonials-module--grid-span-2-6--uAlwM";
export var gridSpan27 = "Testimonials-module--grid-span-2-7--z0Lqc";
export var gridSpan28 = "Testimonials-module--grid-span-2-8--fJ6DW";
export var gridSpan29 = "Testimonials-module--grid-span-2-9--fvPRT";
export var gridSpan310 = "Testimonials-module--grid-span-3-10--1-CwD";
export var gridSpan311 = "Testimonials-module--grid-span-3-11--ARwcR";
export var gridSpan32 = "Testimonials-module--grid-span-3-2--t4fko";
export var gridSpan33 = "Testimonials-module--grid-span-3-3--I1ZAQ";
export var gridSpan34 = "Testimonials-module--grid-span-3-4--mDmYY";
export var gridSpan35 = "Testimonials-module--grid-span-3-5--ocBVS";
export var gridSpan36 = "Testimonials-module--grid-span-3-6--EWPBj";
export var gridSpan37 = "Testimonials-module--grid-span-3-7--fIsPA";
export var gridSpan38 = "Testimonials-module--grid-span-3-8--BcUMl";
export var gridSpan39 = "Testimonials-module--grid-span-3-9--rQzcP";
export var gridSpan410 = "Testimonials-module--grid-span-4-10--z59or";
export var gridSpan411 = "Testimonials-module--grid-span-4-11--a8Qv1";
export var gridSpan42 = "Testimonials-module--grid-span-4-2--DrV8W";
export var gridSpan43 = "Testimonials-module--grid-span-4-3--PJpho";
export var gridSpan44 = "Testimonials-module--grid-span-4-4--B1A4F";
export var gridSpan45 = "Testimonials-module--grid-span-4-5--d+rfa";
export var gridSpan46 = "Testimonials-module--grid-span-4-6--OHha7";
export var gridSpan47 = "Testimonials-module--grid-span-4-7--3l2lT";
export var gridSpan48 = "Testimonials-module--grid-span-4-8--sTXPy";
export var gridSpan49 = "Testimonials-module--grid-span-4-9--pZSYt";
export var gridSpan510 = "Testimonials-module--grid-span-5-10--j2NUx";
export var gridSpan511 = "Testimonials-module--grid-span-5-11--WrKAH";
export var gridSpan52 = "Testimonials-module--grid-span-5-2--u28Rq";
export var gridSpan53 = "Testimonials-module--grid-span-5-3--kuEUw";
export var gridSpan54 = "Testimonials-module--grid-span-5-4--jB58Y";
export var gridSpan55 = "Testimonials-module--grid-span-5-5--gZrgu";
export var gridSpan56 = "Testimonials-module--grid-span-5-6--Xt4e8";
export var gridSpan57 = "Testimonials-module--grid-span-5-7--tI3Ww";
export var gridSpan58 = "Testimonials-module--grid-span-5-8--Va0S3";
export var gridSpan59 = "Testimonials-module--grid-span-5-9--Kfz-J";
export var gridSpan610 = "Testimonials-module--grid-span-6-10--uDMaG";
export var gridSpan611 = "Testimonials-module--grid-span-6-11--Z4aGe";
export var gridSpan62 = "Testimonials-module--grid-span-6-2--E98h2";
export var gridSpan63 = "Testimonials-module--grid-span-6-3--CVLQs";
export var gridSpan64 = "Testimonials-module--grid-span-6-4--BC8tG";
export var gridSpan65 = "Testimonials-module--grid-span-6-5--pJI7L";
export var gridSpan66 = "Testimonials-module--grid-span-6-6--13tjT";
export var gridSpan67 = "Testimonials-module--grid-span-6-7--fJRnq";
export var gridSpan68 = "Testimonials-module--grid-span-6-8--Vx448";
export var gridSpan69 = "Testimonials-module--grid-span-6-9--bQCoY";
export var gridSpan710 = "Testimonials-module--grid-span-7-10--HYtkZ";
export var gridSpan711 = "Testimonials-module--grid-span-7-11--py7-S";
export var gridSpan72 = "Testimonials-module--grid-span-7-2--iQTV0";
export var gridSpan73 = "Testimonials-module--grid-span-7-3--gT1IZ";
export var gridSpan74 = "Testimonials-module--grid-span-7-4--QIVZ4";
export var gridSpan75 = "Testimonials-module--grid-span-7-5---REaL";
export var gridSpan76 = "Testimonials-module--grid-span-7-6--1jdfw";
export var gridSpan77 = "Testimonials-module--grid-span-7-7--VRkET";
export var gridSpan78 = "Testimonials-module--grid-span-7-8--NjOvv";
export var gridSpan79 = "Testimonials-module--grid-span-7-9--Ndtze";
export var gridSpan810 = "Testimonials-module--grid-span-8-10--4h4wJ";
export var gridSpan811 = "Testimonials-module--grid-span-8-11--sQaIG";
export var gridSpan82 = "Testimonials-module--grid-span-8-2--4gOOW";
export var gridSpan83 = "Testimonials-module--grid-span-8-3--+qc2s";
export var gridSpan84 = "Testimonials-module--grid-span-8-4--5KgAo";
export var gridSpan85 = "Testimonials-module--grid-span-8-5--Vf9Br";
export var gridSpan86 = "Testimonials-module--grid-span-8-6--8bhsi";
export var gridSpan87 = "Testimonials-module--grid-span-8-7--n2QDe";
export var gridSpan88 = "Testimonials-module--grid-span-8-8--A23Cx";
export var gridSpan89 = "Testimonials-module--grid-span-8-9--szGdm";
export var gridSpan910 = "Testimonials-module--grid-span-9-10--9KWWY";
export var gridSpan911 = "Testimonials-module--grid-span-9-11--SbLan";
export var gridSpan92 = "Testimonials-module--grid-span-9-2--QZOcY";
export var gridSpan93 = "Testimonials-module--grid-span-9-3--YwuxL";
export var gridSpan94 = "Testimonials-module--grid-span-9-4--0yPUj";
export var gridSpan95 = "Testimonials-module--grid-span-9-5--Fiyd9";
export var gridSpan96 = "Testimonials-module--grid-span-9-6--jzR9q";
export var gridSpan97 = "Testimonials-module--grid-span-9-7--DWahS";
export var gridSpan98 = "Testimonials-module--grid-span-9-8--pFiYh";
export var gridSpan99 = "Testimonials-module--grid-span-9-9--MBgq5";
export var textBreak = "Testimonials-module--text-break--BFqYr";