import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { StaticImage } from "gatsby-plugin-image";
import { APP_SIGNUP_URL } from "data/config";

import * as styles from "./Hero.module.scss";

const Hero: React.FC = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <div className={styles.Hero_bg}>
          <StaticImage
            src="../../../assets/media/images/cards/cards-hero-bg.png"
            alt="hero-bg"
            loading="eager"
          />
        </div>

        <div className={styles.Hero_content}>
          <h1>Corporate cards with built-in control, ready for business</h1>
          <p>
            Enable corporate cards for your teams’ spending, travel, operations,
            and procurement with controls and built-in visibility.
          </p>
          <Button
            variant={ButtonVariant.Primary}
            className={styles.Hero_content_btn}
            onClick={() => window.open(APP_SIGNUP_URL, "_target")}
          >
            Get started in minutes
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
