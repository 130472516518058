import React from "react";

import * as styles from "./Testimonials.module.scss";
import { IconCircleArrow, Play } from "assets/media/svgs";
import { CARDS_DATA } from "data/pages";

const Testimonials: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <section className={styles.Testimonials}>
      <h3 className={styles.Testimonials_title}>
        Trusted by thousands of businesses today
      </h3>

      <div className={styles.Testimonials_content}>
        <div className={styles.Testimonials_content_details}>
          <p className={styles.Testimonials_content_details_company}>
            {CARDS_DATA.TESTIMONIALS[activeIndex].company}
          </p>

          <div className={styles.Testimonials_content_details_info}>
            <p className={styles.Testimonials_content_details_info_quote}>
              “{CARDS_DATA.TESTIMONIALS[activeIndex].quote}”
            </p>

            <div className={styles.Testimonials_content_details_info_controls}>
              <button
                className={
                  styles.Testimonials_content_details_info_controls_btn
                }
                disabled={activeIndex === 0}
                onClick={() => setActiveIndex(activeIndex - 1)}
              >
                <IconCircleArrow />
              </button>
              <button
                className={
                  styles.Testimonials_content_details_info_controls_btn
                }
                disabled={activeIndex === CARDS_DATA.TESTIMONIALS.length - 1}
                onClick={() => setActiveIndex(activeIndex + 1)}
              >
                <IconCircleArrow />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.Testimonials_content_img}>
          <img
            src={CARDS_DATA.TESTIMONIALS[activeIndex].media}
            alt={CARDS_DATA.TESTIMONIALS[activeIndex].company}
          />
        </div>

        <div className={styles.Testimonials_content_mobile}>
          <button
            className={styles.Testimonials_content_mobile_btn}
            disabled={activeIndex === 0}
            onClick={() => setActiveIndex(activeIndex - 1)}
          >
            <IconCircleArrow />
          </button>
          <button
            className={styles.Testimonials_content_mobile_btn}
            disabled={activeIndex === CARDS_DATA.TESTIMONIALS.length - 1}
            onClick={() => setActiveIndex(activeIndex + 1)}
          >
            <IconCircleArrow />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
