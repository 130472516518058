import React from "react";
import { PageProps, graphql } from "gatsby";
import SEO from "components/SEO";
import { FaqWidget, GetStarted } from "components/design-system";
import {
  Hero,
  CorporateCards,
  ExpenseManagement,
  Testimonials,
  TextBanner,
  ImageBanner,
  Support,
} from "components/cards";
import pagesMeta from "data/pagesMeta";

const Cards: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.cards} />
      <Hero />
      <CorporateCards />
      {/* <ExpenseManagement /> */}
      <Testimonials />
      <TextBanner />
      <ImageBanner />
      <Support />
      <FaqWidget content={pageData.faqContent} />
      <GetStarted
        desc="Get started for free, in minutes. Start your business growth journey with us."
        buttonText="Get started"
      />
    </>
  );
};

export const query = graphql`
  query CardsPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "cards" } } }
    ) {
      edges {
        node {
          frontmatter {
            faqContent {
              answer
              question
            }
          }
        }
      }
    }
  }
`;

export default Cards;
